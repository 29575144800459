<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <v-alert class="mb-6" type="info">
        Feel free to return to this task at any time for a review of how the chemical inputs
        function in Stemble. Always verify that what you typed corresponds to what you had intended
        by looking below the input field to view the formatted chemical equation.
      </v-alert>

      <p class="mb-3" style="font-weight: bold; font-size: 1.4em">
        Stemble's Chemical Notation Tutorial
      </p>

      <p class="mb-6 pl-4">
        Stemble's chemical reaction input methods are all keyboard based. This task will guide you
        through how to use our chemical reaction input for questions revolving around writing and
        balancing chemical reactions.
      </p>

      <p class="mb-2" style="font-weight: bold">Chemical Notation</p>

      <p class="mb-6 pl-4">
        To write a chemical species, simply type it out as you would write it out with no need for
        special characters for subscripts denoting the number of a given species. To demonstrate you
        understand this, write the chemical formula for
        <chemical-latex content="Fe3(PO4)2(aq)" />
        in the input field below.
      </p>

      <chemical-notation-input
        v-model="inputs.input1"
        dense
        :show-note="false"
        class="mb-4 pl-4"
        :disabled="!allowEditing"
      />

      <p class="mb-2" style="font-weight: bold">Charges</p>

      <p class="mb-6 pl-4">
        One area that may require special notation is for the assigning of charges to ions. If the
        charge is simply +/- 1, then no special notation is required. To demonstrate this, please
        enter
        <chemical-latex content="OH-(aq)" />
        in the input field below.
      </p>

      <chemical-notation-input
        v-model="inputs.input2"
        dense
        :show-note="false"
        class="mb-4 pl-4"
        :disabled="!allowEditing"
      />

      <p class="mb-2" style="font-weight: bold">Charges Greater than 1</p>

      <p class="mb-6 pl-4">
        In the event that the charge is greater than 1, a carat "^" must be used before the charge
        to denote that the text that follows indicates a charge. To show you understand this, use
        proper notation to input
        <chemical-latex content="Fe^2+(aq)" />
        below. Do not enter a space between the carat and any part of the charge.
      </p>

      <chemical-notation-input
        v-model="inputs.input3"
        dense
        :show-note="false"
        class="mb-4 pl-4"
        :disabled="!allowEditing"
      />

      <p class="mb-2" style="font-weight: bold">Multiple Species</p>

      <p class="mb-6 pl-4">
        When you have multiple species on the reactant or product side of a reaction, you have to
        add the other species to the existing ones. This is simply done by putting a + sign between
        two reagents. However, these reagents must be separated from the + sign by a space otherwise
        the system will think it's a charge. Use proper notation to input
        <chemical-latex content="3A + 2B+" />
        in the field below to demonstrate your understanding of this concept.
      </p>

      <chemical-notation-input
        v-model="inputs.input4"
        dense
        :show-note="false"
        class="mb-4 pl-4"
        :disabled="!allowEditing"
      />

      <p class="mb-2" style="font-weight: bold">Reaction Arrows</p>

      <p class="mb-6 pl-4">
        Thus far we have discussed reagents, and sums of reagents, but what about the reaction
        itself? To input a reaction arrow simply type -> into the input field. Please use proper
        notation to enter
        <chemical-latex content="A^2+ + 2B -> 3C+ + D-" />
        into the field below.
      </p>

      <chemical-notation-input
        v-model="inputs.input5"
        dense
        :show-note="false"
        class="mb-8 pl-4"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';

export default {
  name: 'ChemicalReactionsTestQuestion',
  components: {
    ChemicalLatex,
    ChemicalNotationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
    };
  },
};
</script>
